import React from 'react';
import { createContext, useState, ReactNode } from 'react';

export type DictionaryItem = {
  key: string;
  tooltip: {
    type: 'dialogue' | 'tooltip';
    title: string;
    text: string;
    button?: React.ReactElement;
  };
};

const useAdsValue = () => {
  const [headerHeight, setHeaderHeight] = React.useState<number>(0);
  const [topHeaderHeight, setTopHeaderHeight] = React.useState<number>(0);
  const [ids, setIds] = React.useState<string[]>([]);
  const [dictionary, setDictionary] = React.useState<DictionaryItem[]>([]);
  const [allCookiesAccepted, setAllCookiesAccepted] = useState<
    boolean | undefined
  >(undefined);
  const [marketingCookiesAccepted, setMarketingCookiesAccepted] = useState<
    boolean | undefined
  >(undefined);
  const [activeDictionary, setActiveDictionary] = React.useState<
    DictionaryItem | undefined
  >(undefined);

  return {
    dictionary,
    setDictionary: React.useCallback<typeof setDictionary>(
      (newDictionary) => setDictionary(newDictionary),
      []
    ),
    activeDictionary,
    setActiveDictionary,
    headerHeight,
    setHeaderHeight,
    topHeaderHeight,
    setTopHeaderHeight,
    ids,
    setIds: React.useCallback<typeof setIds>((newIds) => setIds(newIds), []),
    allCookiesAccepted,
    setAllCookiesAccepted,
    marketingCookiesAccepted,
    setMarketingCookiesAccepted,
  };
};

export const AdsContext = React.createContext<
  (ReturnType<typeof useAdsValue> & InitialAdsValues) | null
>(null);

/**
 * We add these mappings to the Context so we can use them in ads-core UI components that use the RichText component
 */
export type MappedComponents = {
  img?: (props: {
    src: string;
    width: number;
    height: number;
    alt: string;
  }) => React.ReactElement;
  a?: (props: {
    href: string;
    children: React.ReactNode;
  }) => React.ReactElement;
};

type InitialAdsValues = {
  components?: MappedComponents;
};

type ProviderProps = InitialAdsValues & {
  children: React.ReactNode;
};

export function AdsProvider({ children, components }: ProviderProps) {
  const value = useAdsValue();
  return (
    <AdsContext.Provider value={{ ...value, components }}>
      {children}
    </AdsContext.Provider>
  );
}

export const useAdsContext = () => {
  const context = React.useContext(AdsContext);
  if (context === null) {
    throw new Error('useAdsContext must be used within a AdsProvider');
  }
  return context;
};

interface DialogContextProps {
  isOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
}

export const DialogContext = createContext<DialogContextProps | null>(null);

export const useDialogContext = () => {
  const context = React.useContext(DialogContext);
  if (context === null) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  return context;
};

interface DialogProviderProps {
  children: ReactNode;
}

export function DialogProvider({ children }: DialogProviderProps) {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDialog = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <DialogContext.Provider value={{ isOpen, openDialog, closeDialog }}>
      {children}
    </DialogContext.Provider>
  );
}
