import React from 'react';
import NextLink, { type LinkProps } from 'next/link';
import { __IS_DEVELOPMENT } from 'src/data/constants';

type SharedKeys = 'href' | 'onMouseEnter' | 'onClick' | 'onTouchStart';
type SpecificNextKeys = Exclude<keyof LinkProps, SharedKeys>;

const specificNextKeys: SpecificNextKeys[] = [
  'as',
  'legacyBehavior',
  'locale',
  'passHref',
  'prefetch',
  'replace',
  'scroll',
  'shallow',
];

// By checking if the href is an internal link, we can reliably discriminate between the two link interfaces
function isInternalLink(props: InternalOrExternalProps): props is NextLinkProps {
  const href = typeof props.href === 'string' ? props.href : props.href?.pathname;
  return !!href?.startsWith('/');
}

// We need to discriminate between the two link interfaces in order to successfully render the correct component
type NextLinkProps = Omit<React.ComponentProps<'a'>, 'ref' | 'href'> &
  LinkProps & { children: React.ReactNode };
type AnchorProps = React.ComponentProps<'a'> & { children: React.ReactNode } & {
  [K in keyof Omit<LinkProps, SharedKeys>]: never;
};

type InternalOrExternalProps = NextLinkProps | AnchorProps;

/**
 * Whenever the `href` starts with a `/` or contains specific Next.js props, we render a NextLink. Otherwise, we render a plain anchor tag
 */
export const InternalOrExternalLink = React.forwardRef<HTMLAnchorElement, InternalOrExternalProps>(
  (props, ref) => {
    if (!props.href) return null;

    if (isInternalLink(props)) {
      return <NextLink ref={ref} {...props} />;
    }

    if (__IS_DEVELOPMENT) {
      // Give a warning whenever a Next.js specific prop is used on an external link
      const hasNextKeys = specificNextKeys.some((k) => Object.keys(props).includes(k));
      if (hasNextKeys) {
        console.warn(
          'You are using a Next.js specific prop on an external link. This will not work as expected. Please remove the prop or use a different component'
        );
      }
    }

    return <a ref={ref} {...props} />;
  }
);

InternalOrExternalLink.displayName = 'InternalOrExternalLink';
