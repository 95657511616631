import React from 'react';
import { LayoutServicePageState } from '@sitecore-jss/sitecore-jss-nextjs';
import NextImage from 'next/image';

type ImageProviderProps = {
  src: string;
  width: number;
  height: number;
  alt: string;
  pageState?: LayoutServicePageState;
};

/**
 * The Image exist to know the context of the page.
 * When the pageState isn't 'normal' we know it will be rendered in the frontend.
 * Otherwise it will be rendered in the Sitecore editor or preview.
 * This way we can make sure that the images are always visible in Sitecore.
 *
 * @todo:
 * Investigate if we should use the SitecoreImage instead of the html img
 * Investigate if we should get the pageState from the useSitecoreContext instead of prop drilling.
 *
 */

export const Image = ({ pageState, ...imageProps }: ImageProviderProps) => {
  if (pageState && pageState !== 'normal') {
    return <img {...imageProps} />;
  }

  return (
    <NextImage
      {...imageProps}
      quality={100}
      sizes="(min-width: 320px) 100vw, (min-width: 768px) 80vw, (min-width: 1024px) 50vw, (min-width: 1680px) 1680px, 100vw"
    />
  );
};
