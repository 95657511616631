import React from 'react';
import { LayoutProps } from 'src/pages/_app';

type SitecoreContextProps = LayoutProps['layoutData']['sitecore'] & {
  context: { segment?: 'Grootzakelijk' | 'Over ons' | 'Thuis en kleinzakelijk' };
};

const SitecoreContext = React.createContext<SitecoreContextProps | null>(null);

type SitecoreContextProviderProps = {
  children: React.ReactNode;
} & SitecoreContextProps;

export const SitecoreContextProvider = ({
  children,
  context,
  route,
}: SitecoreContextProviderProps) => {
  return <SitecoreContext.Provider value={{ context, route }}>{children}</SitecoreContext.Provider>;
};

export const useSitecoreContext = () => {
  const ctx = React.useContext(SitecoreContext);
  if (!ctx) {
    throw new Error('useSitecoreContext must be used within a SitecoreContextProvider');
  }

  return ctx;
};
