import '../../global/global.css';
import React from 'react';
import { themeClass } from '../../global/theme.css';

export const ThemeContainer = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => {
  return (
    <div className={themeClass} ref={ref}>
      {children}
    </div>
  );
});
