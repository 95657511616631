import React from 'react';
import * as styles from './JumpToMainContent.css';

export const JumpToMainContent = () => {
  return (
    <div className={styles.wrapper} tabIndex={-1} id="skip-to-content">
      <a href="#top-of-content" className={styles.button}>
        Ga naar de inhoud
      </a>
    </div>
  );
};
