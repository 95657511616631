/**
 * These are constants that are used throughout the Liander application. These contants are identical on all environments, so we don't use environment variables.
 */
export const COOKIEBOT_CBID = '6e286ee5-e2a8-445e-aed3-36cad708640a';

export const ARCGIS_PORTAL_ITEM_ID = '822dff944ec5451bb053f5969a92df41';

export const CACHE_CONTROL_AGES = {
  NONE: 0, // 0 seconds
  MINUTE: 60, // 1 minute
  HOUR: 60 * 60, // 1 hour
  DAY: 60 * 60 * 24, // 1 day
  MONTH: 60 * 60 * 24 * 30, // 1 month
  YEAR: 60 * 60 * 24 * 365, // 1 year
};

export const __IS_DEVELOPMENT = process.env.NODE_ENV !== 'production';
export const __IS_PRODUCTION = process.env.NODE_ENV === 'production';
